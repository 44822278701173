<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-43.mp3"),
      },
      timeStampList: [5, 7.5, 10.8, 21.8, 24, 27.5, 33],
      // 22
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hē shénme? ",
            chineseWords: "你喝什么？",
          },
          {
            id: 2,
            spelling: "Zhèr yǒu shuǐ, yě yǒu niúnǎi.",
            chineseWords: "这儿有水，也有牛奶。",
          },
          {
            id: 3,
            spelling: "Wǒ hē niúnǎi. Xièxie nǐ!",
            chineseWords: "我喝牛奶。谢谢你！",
          },
          {
            id: 4,
            spelling: "Nǐ chī shénme?",
            chineseWords: "你吃什么？",
          },
          {
            id: 5,
            spelling: "Zhèr yǒu yú, yě yǒu píngguǒ.",
            chineseWords: "这儿有鱼，也有苹果。",
          },
          {
            id: 6,
            spelling: "Wǒ chī píngguǒ. Xièxie nǐ.",
            chineseWords: "我吃苹果。谢谢你。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
